import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { startDKApp } from '@core/app'
import { bootstrap } from '@core/bootstrap'

import ConvertTags from './app/modules/convertTags'
import Accordion from './app/modules/accordion'
import Anchors from './app/modules/anchors'
import CurrentYear from './app/modules/currentYear'
import Dialog from './app/modules/dialog'
import Nav from './app/modules/nav'

import ContactForm from './app/components/ContactForm'

startDKApp(() => {
  ConvertTags()
  Accordion()
  Anchors()
  CurrentYear()
  Dialog()
  Nav()
  bootstrap('contact-form', [ContactForm], {})
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
